import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Row from "../components/row"
import SEO from "../components/seo"
import Slider from "../components/slider"
import Heading from "../components/heading"
import { seoDataFromPage } from "components/utils/data-formatting"

import p from "./single/projecten.module.scss"
import l from "../components/layout/layout.module.scss"

const ProjectSingle = ({ pageContext, data: queryData }) => {
  const { title } = pageContext
  const { acf } = queryData.wordpressPost
  const meta = seoDataFromPage(queryData.wordpressPost)

  const slideImages =
    acf.images && acf.images.length > 0
      ? acf.images
      : [queryData.wordpressPost.fallback_img]

  const showArrowsAndDots = slideImages.length > 1

  return (
    <Layout>
      <SEO title={title} meta={meta} />
      <Row customClass={l.archive_title_section}>
        <Link to="/projecten" className={[l.light, l.arrow_link].join(" ")}>
          <div className={[l.arrow, l.left].join(" ")} /> Terug naar projecten
        </Link>
        <Heading type="h1" color="white" className={p.heading}>
          {title}
        </Heading>
      </Row>
      <Row customClass={p.project_wrapper}>
        <Slider
          arrows={showArrowsAndDots}
          dots={showArrowsAndDots}
          customClass={p.slider_c}
          slides={slideImages}
        />
      </Row>
    </Layout>
  )
}

export default ProjectSingle

// add page query
export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    wordpressPost(wordpress_id: { eq: $wordpress_id }) {
      fallback_img: featured_media {
        ...ImageDefault
      }
      acf {
        images {
          ...ImageDefault
        }
      }
      ...SEODataPost
    }
  }
`
